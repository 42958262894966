// require("./insta.js");
require("./TweenMax.min.js");
import 'magnific-popup';

jQuery( document ).ready(function($) {
    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $('.mfp-inline').magnificPopup({
        type: 'inline'
    })

	var rect = $('.photos')[0].getBoundingClientRect();
    var mouse = {x: 0, y: 0, moved: false};

    $(".photos").mousemove(function(e) {
		console.log('mouse')
        mouse.moved = true;
        mouse.x = e.clientX - rect.left;
        mouse.y = e.clientY - rect.top;
    });

    TweenLite.ticker.addEventListener('tick', function(){
    if (mouse.moved){    
        parallaxIt(".img2", -15);
        parallaxIt(".img1", -25);
    }
    mouse.moved = false;
    });

    
    function parallaxIt(target, movement) {
    TweenMax.to(target, 0.5, {
        x: (mouse.x - rect.width / 2) / rect.width * movement,
        y: (mouse.y - rect.height / 2) / rect.height * movement
    });
    }

    $(window).on('resize scroll', function(){
        rect = $('.photos')[0].getBoundingClientRect();
    });
    
});